<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <h1>{{ pageTitle }}</h1>
      <hr />
    </div>
    <div>
      <h2>{{ $t("subTitle.baseInformation") }}</h2>
      <el-form
        ref="courseSession"
        :model="courseSession"
        @submit.prevent.native="submit"
        :validate-on-rule-change="false"
        label-position="right"
        label-width="150px"
      >
        <el-form-item
          :label="$t('courseSessions.course')"
          :rules="[commonRules.input]"
          prop="course_id"
        >
          <el-select
            v-model="courseSession.course_id"
            :placeholder="$t('courseSessions.course')"
            required
          >
            <el-option
              v-for="course in courses"
              :key="course.id"
              :label="course.title.tw"
              :value="course.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('courseSessions.title') + ' (EN)'"
          :rules="[commonRules.input]"
          :prop="'title.en'"
        >
          <el-input v-model="courseSession.title.en" required></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('courseSessions.title') + ' (TW)'"
          :rules="[commonRules.input]"
          :prop="'title.tw'"
        >
          <el-input v-model="courseSession.title.tw"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('courseSessions.title') + ' (CN)'"
          :rules="[commonRules.input]"
          :prop="'title.cn'"
        >
          <el-input v-model="courseSession.title.cn"></el-input>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.short_name')">
          <el-input v-model="courseSession.short_name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.city')">
          <el-input v-model="courseSession.city"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('courseSessions.fee')"
          :rules="[commonRules.input]"
          prop="fee"
        >
          <!-- <el-input-number v-model.number="courseSession.fee"></el-input-number> -->
          <el-row>
            <el-col :span="10">
              <InputMoney
                :money="courseSession.fee"
                :currency="courseSession.fee_currency"
                @changeMoney="
                  fee => {
                    courseSession.fee = fee;
                  }
                "
                @changeCurrency="
                  currency => {
                    courseSession.fee_currency = currency;
                  }
                "
              />
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item required :label="$t('courseSessions.need_pay_first')">
          <el-switch
            v-model="needPayFirst"
            :active-text="$t('courseSessions.pay_yes')"
            :inactive-text="$t('courseSessions.pay_no')"
          ></el-switch>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.is_online')">
          <el-switch
            v-model="courseSession.is_online"
            :active-text="$t('courseSessions.online')"
            :inactive-text="$t('courseSessions.in_class')"
          >
          </el-switch>
        </el-form-item>

        <el-form-item :label="$t('courseSessions.start_date')">
          <el-date-picker
            v-model="courseSession.started_on"
            value-format="yyyy-MM-dd"
            type="date"
            :placeholder="$t('courseSessions.date')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.end_date')">
          <el-date-picker
            v-model="courseSession.ended_on"
            value-format="yyyy-MM-dd"
            type="date"
            :placeholder="$t('courseSessions.date')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.time_from')">
          <el-time-select
            v-model="courseSession.time_from"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:45'
            }"
            :placeholder="$t('courseSessions.time')"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.time_to')">
          <el-time-select
            v-model="courseSession.time_to"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:45'
            }"
            :placeholder="$t('courseSessions.time')"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.tests_templates')">
          <el-select
            v-if="templates !== undefined"
            style="width:100%"
            v-model="template"
            placeholder="(You can choose the tests from an existing section or type in your own.)"
            @change="setTests"
          >
            <el-option-group
              v-if="templates.course_sessions.length > 0"
              :label="$t('courseSessions.sessions_templates')"
            >
              <el-option
                v-for="(testPaper, index) in templates.course_sessions"
                :key="index + 1"
                :label="testPaper.course_session_title"
                :value="testPaper.course_session_assign_tests"
              >
              </el-option>
            </el-option-group>
            <el-option-group
              v-if="templates.session_classes.length > 0"
              :label="$t('courseSessions.classes_templates')"
            >
              <el-option
                v-for="(testPaper, index) in templates.session_classes"
                :key="index + 1"
                :label="testPaper.title"
                :value="testPaper.session_class_assign_tests"
              >
              </el-option>
            </el-option-group>
          </el-select>
          <div
            v-for="(test, index) in courseSession.course_session_assign_tests"
            :key="index"
          >
            <hr />
            <h5>
              Test{{ index }}
              <span class="pl-20">
                <el-date-picker
                  v-model="test.exam_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="選擇考試日期"
                >
                </el-date-picker>
              </span>
              <el-select
                filterable
                style="width:50%"
                v-model="test.exam_id"
                placeholder="選擇考卷"
              >
                <el-option
                  v-for="(item, index) in sessionsTests"
                  :key="index"
                  :label="item.full_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-button
                type="text"
                class="text-danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeTest(index)"
              ></el-button>
            </h5>
          </div>
          <hr />
          <el-button type="success" @click="addTest">Add Test</el-button>
        </el-form-item>

        <el-form-item>
          <template slot="label">
            <div style="text-align: left">
              {{ $t("courseSessions.tags") }}
            </div>
          </template>
          <span v-for="item in enrollmentSessionTags" :key="item.id">
            <input
              type="checkbox"
              :value="item.id"
              v-model="checkedEnrollmentSessionTags"
            />
            <span class="checkbox-label"> {{ item.name }} </span> <br />
          </span>
        </el-form-item>

        <el-form-item :label="$t('courseSessions.active')" prop="is_visible">
          <el-switch
            v-model="isVisible"
            active-text="Yes"
            inactive-text="No"
          ></el-switch>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            $t("courseSessions.save")
          }}</el-button>
        </el-form-item>
      </el-form>
      <hr />
      <h2>{{ $t("subTitle.classTicket") }}</h2>
      <span>{{ $t("courseSessions.whichSessionIncludedInTheSession") }}</span>
      <div>
        <AddClassTicketTable />
      </div>
    </div>
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";
import { InputMoney } from "@/components/input";
import courseApi from "@/apis/course";
import formMixin from "@/mixins/form";
import enrollmentApi from "@/apis/enrollment";
import Breadcrumb from "@/components/Breadcrumb";
import AddClassTicketTable from "@/views/courseSessions/AddClassTicketTable";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },

  components: { InputMoney, Breadcrumb, AddClassTicketTable },

  mixins: [formMixin],

  props: [],
  data() {
    return {
      sessionId: 0,
      courses: [],
      courseSession: {},
      template: null,
      templates: {},
      testPapers: [],
      showSession: [],
      sessionsTests: [],
      enrollmentSessionTags: [],
      checkedEnrollmentSessionTags: []
    };
  },
  computed: {
    helper() {
      return helper;
    },
    pageTitle() {
      return `Edit ${this.helper.displayI18nText(this.$i18n.locale, this.courseSession.title)}`;
    },
    // element switch 似乎只認 true/false, 不能用0/1，所以進行轉換
    isOnline: {
      get() {
        return this.courseSession.is_online == 1 ? true : false;
      },
      set(value) {
        this.courseSession.is_online = value == true ? 1 : 0;
      }
    },
    needPayFirst: {
      get() {
        return this.courseSession.need_pay_first == 1 ? true : false;
      },
      set(value) {
        this.courseSession.need_pay_first = value == true ? 1 : 0;
      }
    },
    isVisible: {
      get() {
        return this.courseSession.is_visible == 1 ? true : false;
      },
      set(value) {
        this.courseSession.is_visible = value == true ? 1 : 0;
      }
    }
  },
  watch: {},

  async mounted() {
    let coursesRes = await courseApi.fetchCourses({ group_courses_only: 1 });
    this.getAssignTest();
    this.getTemplates();
    this.courses = coursesRes.courses;
    let courseSessionRes = await courseApi.fetchSession(this.$route.params.id);
    this.courseSession = {
      ...courseSessionRes.course_session,
      fee: Number(courseSessionRes.course_session.fee)
    };
    let testPapersRes = await enrollmentApi.fetchTestPapers();
    this.testPapers = testPapersRes.test_papers;
    let enrollmentSessionTagsRes = await courseApi.fetchEnrollmentSessionTags();
    this.enrollmentSessionTags =
      enrollmentSessionTagsRes.enrollment_session_tags;
    this.checkedEnrollmentSessionTags = this.courseSession.enrollment_session_tags.map(
      item => item.id
    );
  },
  methods: {
    async getTemplates() {
      let res = await courseApi.getTemplates();
      this.templates = res;
    },
    setTests(val) {
      if (val.length > 0) {
        let arr = [];
        val.forEach((element, index) => {
          let test = {
            exam_id: element.exam_id
          };
          arr.push(test);
        });
        this.courseSession.course_session_assign_tests = arr;
      } else {
        this.courseSession.course_session_assign_tests = [];
      }
    },
    addTest() {
      this.courseSession.course_session_assign_tests.push({
        exam_id: ""
      });
    },
    removeTest(index) {
      this.courseSession.course_session_assign_tests.splice(index, 1);
    },
    async getAssignTest() {
      const tests = await courseApi.getExams();
      this.sessionsTests = tests.exams;
    },

    async onSubmit() {
      const valid = await this.validateForm(this.$refs["courseSession"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }
      this.courseSession.tag_ids = this.checkedEnrollmentSessionTags;
      courseApi
        .postSession(this.courseSession.id, this.courseSession)
        .then(response => {
          this.$message({
            showClose: true,
            message: this.$t("message.update_success"),
            type: "success"
          });
          this.routerPush({
            name: "courseSessionsList"
          }).catch(error => {
            this.$message({
              showClose: true,
              message: this.$t("message.something_went_wrong"),
              type: "error"
            });
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
$green: rgb(66, 161, 106);
$grey: #ecedef;
$black: #343434;

.cardWrap {
  width: 27em;
  color: #fff;
  font-family: sans-serif;
}

.card {
  background: linear-gradient(
    to bottom,
    $green 0%,
    $green 26%,
    $grey 26%,
    $grey 100%
  );
  height: 11em;
  float: left;
  position: relative;
  padding: 0.8em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cardLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 16em;
}

.cardRight {
  width: 6.5em;
  border-left: 0.18em dashed #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 0.9em;
    height: 0.9em;
    background: #fff;
    border-radius: 50%;
    left: -0.5em;
  }
  &:before {
    top: -0.4em;
  }
  &:after {
    bottom: -0.4em;
  }
}

.card h1 {
  font-size: 1.1em;
  margin-top: 0;
  color: #fff;
  span {
    font-weight: normal;
  }
}

.title,
.name,
.seat,
.time {
  text-transform: uppercase;
  font-weight: normal;
  h2 {
    font-size: 0.9em;
    color: #525252;
    margin: 0;
  }
  span {
    font-size: 0.7em;
    color: #a2aeae;
  }
}

.title {
  margin: 1em 0 0 0;
}

.name,
.seat {
  margin: 0.7em 0 0 0;
}

.time {
  margin: 0.7em 0 0 1em;
}

.seat,
.time {
  float: left;
}

.eye {
  position: relative;
  width: 2em;
  height: 1.5em;
  background: #fff;
  margin: 0 auto;
  border-radius: 1em/0.6em;
  z-index: 1;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
  }
  &:before {
    width: 1em;
    height: 1em;
    background: $green;
    z-index: 2;
    left: 8px;
    top: 4px;
  }
  &:after {
    width: 0.5em;
    height: 0.5em;
    background: #fff;
    z-index: 3;
    left: 12px;
    top: 8px;
  }
}

.number {
  text-align: center;
  text-transform: uppercase;
  h3 {
    color: $green;
    margin: 0.9em 0 0 0;
    font-size: 2.5em;
  }
  span {
    display: block;
    color: #a2aeae;
  }
}

::v-deep .el-form-item__label {
  line-height: 24px;
}
</style>
