var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{attrs:{"data":_vm.attachAddButton(_vm.classTickets)}},[_c('el-table-column',{attrs:{"label":"Class Ticket"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'addItem')?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.addClassTicket}},[_vm._v(" Add Class Ticket ")]):_c('div',[_c('el-select',{directives:[{name:"show",rawName:"v-show",value:(scope.row.edit),expression:"scope.row.edit"}],attrs:{"filterable":"","value":scope.row.newIncludedCourseSessionId ||
              scope.row.included_course_session_id,"placeholder":"屬於課程梯次"},on:{"change":includedCourseSessionId => {
              _vm.setClassTicket(
                scope.$index,
                scope.row.included_course_session_id,
                includedCourseSessionId
              );
            }}},_vm._l((_vm.courseSessions),function(courseSession){return _c('el-option',{key:courseSession.id,attrs:{"label":_vm.helper.displayI18nText(_vm.$i18n.locale, courseSession.title),"value":courseSession.id}})}),1),(!scope.row.edit)?_c('span',[_vm._v(" "+_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, scope.row.included_course_session.title))+" ")]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.action')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type !== 'addItem')?_c('div',[(scope.row.edit)?_c('div',[_c('el-tooltip',{attrs:{"value":true,"content":_vm.$t('message.remeberSave'),"placement":"top","popper-class":"tooltipColor"}},[_c('span',{key:"check",staticClass:"action-icon",on:{"click":function($event){return _vm.saveClassTicket(scope.row)}}},[_c('i',{staticClass:"fas fa-check"})])]),_c('span',{key:"times",staticClass:"warning-icon",on:{"click":function($event){return _vm.closeEditMode(scope.row, scope.$index)}}},[_c('i',{staticClass:"fas fa-times"})])],1):_c('div',[_c('span',{staticClass:"action-icon",on:{"click":function($event){return _vm.openEditMode(scope.row.included_course_session_id)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('span',{staticClass:"warning-icon",on:{"click":function($event){return _vm.removeClassTicket(scope.row.id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }