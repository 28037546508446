<template>
  <el-table :data="attachAddButton(classTickets)">
    <el-table-column label="Class Ticket">
      <template slot-scope="scope">
        <el-button
          v-if="scope.row.type === 'addItem'"
          type="primary"
          size="mini"
          @click="addClassTicket"
        >
          Add Class Ticket
        </el-button>
        <div v-else>
          <el-select
            filterable
            v-show="scope.row.edit"
            :value="
              scope.row.newIncludedCourseSessionId ||
                scope.row.included_course_session_id
            "
            @change="
              includedCourseSessionId => {
                setClassTicket(
                  scope.$index,
                  scope.row.included_course_session_id,
                  includedCourseSessionId
                );
              }
            "
            placeholder="屬於課程梯次"
          >
            <el-option
              v-for="courseSession in courseSessions"
              :key="courseSession.id"
              :label="helper.displayI18nText($i18n.locale, courseSession.title)"
              :value="courseSession.id"
            >
            </el-option>
          </el-select>
          <span v-if="!scope.row.edit">
            {{ helper.displayI18nText($i18n.locale, scope.row.included_course_session.title) }}
          </span>
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('column.action')">
      <template slot-scope="scope">
        <div v-if="scope.row.type !== 'addItem'">
          <div v-if="scope.row.edit">
            <el-tooltip
              :value="true"
              :content="$t('message.remeberSave')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span
                key="check"
                class="action-icon"
                @click="saveClassTicket(scope.row)"
              >
                <i class="fas fa-check" />
              </span>
            </el-tooltip>
            <span
              key="times"
              class="warning-icon"
              @click="closeEditMode(scope.row, scope.$index)"
            >
              <i class="fas fa-times" />
            </span>
          </div>
          <div v-else>
            <span
              class="action-icon"
              @click="openEditMode(scope.row.included_course_session_id)"
            >
              <i class="fas fa-edit" />
            </span>
            <span class="warning-icon" @click="removeClassTicket(scope.row.id)">
              <i class="fas fa-trash-alt" />
            </span>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { helper } from "@ivy-way/material";
import roleMixin from "@/mixins/role";
import courseApi from "@/apis/course";

export default {
  data() {
    return {
      courseSessions: [],
      classTickets: []
    };
  },

  mixins: [roleMixin],
  computed: {
    helper() {
      return helper;
    }
  },

  async mounted() {
    this.loadClassTickets();
    let sessionRes = await courseApi.fetchSessions();
    this.courseSessions = sessionRes.course_sessions;
  },
  methods: {
    attachAddButton(classTickets) {
      if (!this.isRoleAdmin()) return classTickets;
      return [...classTickets, { type: "addItem" }];
    },
    openEditMode(includedCourseSessionId) {
      this.classTickets = this.classTickets.map(classTicket => {
        if (classTicket.included_course_session_id !== includedCourseSessionId)
          return classTicket;
        return { ...classTicket, edit: true };
      });
    },
    closeEditMode(targetClassTicket, targetIndex) {
      if (!targetClassTicket.id) {
        this.classTickets = this.classTickets.filter(
          (classTicket, index) => index !== targetIndex
        );
      } else {
        this.classTickets = this.classTickets.map(classTicket => {
          if (classTicket.id !== targetClassTicket.id) return classTicket;
          return {
            ...classTicket,
            newIncludedCourseSessionId: null,
            edit: false
          };
        });
      }
    },
    addClassTicket() {
      this.classTickets = [
        ...this.classTickets,
        { included_course_session_id: null, edit: true }
      ];
    },
    setClassTicket(
      targetIndex,
      originIncludedCourseSessionId,
      newIncludedCourseSessionId
    ) {
      if (originIncludedCourseSessionId === newIncludedCourseSessionId) return;
      this.classTickets = this.classTickets.map((classTicket, index) => {
        if (index !== targetIndex) return classTicket;
        return {
          ...classTicket,
          newIncludedCourseSessionId
        };
      });
    },
    isExistClassTicket(newClassTicket) {
      const classTicketIndex = this.classTickets
        .filter(classTicket => !classTicket.edit)
        .findIndex(
          classTicket =>
            classTicket.included_course_session_id ===
            newClassTicket.newIncludedCourseSessionId
        );
      if (classTicketIndex === -1) return false;
      this.$message.error(this.$t("tag.form.theUserAlreadyExist"));
      return true;
    },
    async saveClassTicket(classTicket) {
      if (this.isExistClassTicket(classTicket)) return;
      if (
        classTicket.newIncludedCourseSessionId === undefined ||
        classTicket.newIncludedCourseSessionId ===
          classTicket.included_course_session_id
      ) {
        this.closeEditMode(classTicket);
        return;
      }
      classTicket.id && (await this.removeClassTicket(classTicket.id));
      await courseApi.createClassTicket({
        course_session_id: this.$route.params.id,
        included_course_session_id: classTicket.newIncludedCourseSessionId
      });
      this.loadClassTickets();
    },
    async removeClassTicket(classTicketId) {
      await courseApi.deleteClassTicket(classTicketId);
      this.loadClassTickets();
    },
    async loadClassTickets() {
      let classTicketsRes = await courseApi.fetchClassTickets({
        course_session_id: this.$route.params.id
      });
      this.classTickets = classTicketsRes.class_tickets;
    }
  }
};
</script>
